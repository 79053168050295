<template>
  <div class="container">
    <!-- 导航 -->
    <van-nav-bar left-arrow title="预约会议" @click-left="onClickBackHandle"/>

    <div class="info-loading" v-if="isLoading && meetingId">
      <van-loading size="24px" 
        text-color="#000000" 
        color="#1DAF69">{{$t('seeting.loading')}}</van-loading>
    </div>

    <!-- 表单 -->
    <template v-else>
      <van-cell-group class="group">
      <van-field 
        center
        class="multi-row"
        v-model="detail.title"
        rows="1"
        autosize
        clearable
        maxlength="50"
        type="textarea"
        placeholder="请输入会议主题"/>
    </van-cell-group>

     <van-cell-group class="group">
      <van-cell center title="开始时间" class="auto-cell" is-link @click="onClickStartTimeHandle" :value="detail.beginTime | formatTimeFilter('YYYY年MM月DD日 ddd HH:mm')" />
      <van-cell center title="持续时间" is-link :value="durationLabel" @click="onClickDurationHandle"/>
      <van-cell center title="时区" value="北京时间" />
    </van-cell-group>

    <van-cell-group class="group">
      <van-cell title="会议密码" center>
          <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="detail.passwordEnable" />
      </van-cell>
      <van-field center v-if="detail.passwordEnable" v-model="detail.password" placeholder="请输入4-6位数字密码" />
    </van-cell-group>

    <van-cell-group class="group">
       <van-cell title="主持人密码" center>
          <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="detail.ownerPasswordEnable" />
      </van-cell>
      <van-field center v-if="detail.ownerPasswordEnable" v-model="detail.ownerPassword" placeholder="请输入4-6位数字密码" />
    </van-cell-group>

    <van-cell-group class="group">
      <van-cell title="允许成员在主持人进会前加入会议" center class="auto-cell">
          <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="detail.allowEarlyEntry" />
      </van-cell>
      <van-cell title="成员入会时静音" center>
          <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="detail.muteJoinMeeting" />
      </van-cell>
      <van-cell title="允许成员自我解除静音" center>
          <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="detail.allowSelfUnmute" />
      </van-cell>
      <van-cell title="成员进入时播放提示音" center>
          <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="detail.playTips" />
      </van-cell>
     </van-cell-group>
    
     <van-button type="primary" :disabled="isDisableBtn()"  class="button save" @click="handleSubmitMeetInfo" >{{$t('login.complete')}}</van-button>
    </template>

    <!-- 选择时间弹窗 -->
    <van-popup v-model="showStartTimeWin" position="bottom">
      <van-datetime-picker
          v-if="showStartTimeWin"
          v-model="startTime"
          @confirm="onConfirmStartTime"
          @cancel="onCancelStartTime"
          type="datetime"
          title="选择开始时间"
          :formatter="formatterStartTime"
          :filter="filterStartTime"
          :min-date="minDate"/>
    </van-popup>

    <!-- 选择时长弹窗 -->
    <van-popup v-model="showDurationWin" position="bottom">
      <van-datetime-picker
          v-if="showDurationWin"
          v-model="durationTime"
          @change="onChangeDuration"
          @confirm="onConfirmDuration"
          @cancel="onCancelDuration"
          type="time"
          title="选择时长"
          :formatter="formatterDuration"
          :filter="filterDuration"/>
    </van-popup>
  </div>
</template>

<script>
import '@/utils/filter'
import  { checkSmallPwd } from '@/utils/check'
import { throttle } from 'lodash'
import { THROTLE_TIME } from "@/constant/index";
import { getLocalUserInfo } from "@/utils/auth"

import moment from 'moment'

const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DATETIME_MINUTE_FORMAT = 'YYYY-MM-DD HH:mm'

export default {
  data() {
    return {
       meetingId: this.$route.params.meetingId,
       minDate: new Date(),
       showStartTimeWin: false,
       showDurationWin: false,
       startTime: "",
       durationTime: "01:00",
       lastDurationTime: "01:00",
       durationLabel: '',
       isLoading: true,
       detail:  {
        agenda: "", //会议议程
        agendaPermission: 0, // 会议议程权限 0：所有人可见 1：仅主持人可见
        allowEarlyEntry: true, // 是否允许提前进入
        allowSelfUnmute: true, // 成员加入会议静音
        beginTime: moment().format(DATETIME_FORMAT),
        endTime: "",
        links: "", // 会议链接
        ownerId: "", // 会议主持人ID
        ownerPasswordEnable: false, //主持人密码是否开启
        ownerPassword: "", // 主持人密码
        passwordEnable: false, // 入会密码是否开启
        password: "", // 入会密码
        muteJoinMeeting: true, // 允许成员自我解除静音;
        playTips: false, // 成员加入会议时播放提示音
        title: '',
        type: 1, // 1:预约会议 2:即时会议
        useSelfConference: 0 // 是否使用个人会议号   1:是 0：否
      },
    }
  },

  components: {
  },

  computed: {
  },
  async mounted () {
    //用户信息
    const userInfo =  getLocalUserInfo()
    this.detail.title = `${userInfo.realName}`+ '的预约会议'|| ""

    //默认会议开始时间 整点显示
    if (!this.meetingId) {
      const minutes = moment().minutes()
      if (minutes > 0 && minutes < 30) {
        this.detail.beginTime = moment().minutes(30).format(DATETIME_FORMAT)
      }
      if (minutes > 30) {
        this.detail.beginTime = moment().add(1, 'hours').minutes(0).format(DATETIME_FORMAT)
      }
      this.startTime = new Date(this.detail.beginTime.replace(/-/g, "/"))
      this.showDurationLabel()
      return
    }

    // 获取会议信息
    await this.getMeetingInfo(this.meetingId)

  },

  methods: {
    isDisableBtn(){
      return !this.detail.title
    },
    onClickBackHandle() {
      if(this.$route.query.meetingType == 0 || this.$route.query.meetingType == 1){
      this.$router.push({ name: 'home', query: { active: 1, tab:this.$route.query.meetingType }})
      }else{
        this.$router.push({ name: 'home'})
      }
    },

    //工具函数
    notify(msg) {
      this.$notify({
            message: msg,
            color: '#FF4D4F',
            background: '#ffeded'
        })
    },

    showDurationLabel() {
      let label = ''
      const hours = this.durationTime.split(':')[0]
      if (hours > 0) {
        label += `${Number(hours)}小时`
      }

      const minutes = this.durationTime.split(':')[1]
      if (minutes > 0) {
        label += `${Number(minutes)}分钟`
      }
      this.durationLabel = label
    },

    /**** 开始时间选择器函数 */
    onClickStartTimeHandle() {
      this.showStartTimeWin = true
    },
    onConfirmStartTime() {
      this.detail.beginTime = this.startTime
      this.showStartTimeWin = false
    },
    onCancelStartTime() {
      this.showStartTimeWin = false
    },
    formatterStartTime(type, val) {
      switch (type) {
        case 'year':
          return val + '年'
        case 'month':
          return val + '月'
        case 'day':
          return val + '日'
        case 'hour':
          return val + '时'
        case 'minute':
          return val + '分'
        default:
          return val
      } 
    },
    filterStartTime(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 15 === 0)
      }
      return options
    },

    /**** 持续时间选择器函数 */
    onClickDurationHandle(){
      this.lastDurationTime = this.durationTime
      this.showDurationWin = true
    },
    onChangeDuration(picker) {
      //限制不允许选择00:00
      const hours = this.durationTime.split(':')[0]
      const minutes = this.durationTime.split(':')[1]
      if (hours === '00' && minutes === '00') {
        this.durationTime = '00:15'
      }
    },
    onConfirmDuration() {
      const hours = this.durationTime.split(':')[0]
      const minutes = this.durationTime.split(':')[1]
      if (hours === '00' && minutes === '00') {
        return
      }

      this.detail.endTime = moment(this.detail.beginTime)
        .add({ hours, minutes })
        .format(DATETIME_FORMAT)
      
      this.showDurationLabel()
      this.showDurationWin = false
    },
    onCancelDuration() {
      this.durationTime = this.lastDurationTime
      this.showDurationLabel()
      this.showDurationWin = false
    },
    formatterDuration(type, val) {
      switch (type) {
        case 'hour':
          return val + '小时'
        case 'minute':
          return val + '分钟'
        default:
          return val
      } 
    },
    filterDuration(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 15 === 0)
      }
      return options
    },

    async getMeetingInfo(meetingId){
      if(!meetingId) return;

      await sdk.meetingManagement.getMeetingInfo(meetingId).then(data=>{
        this.isLoading = false

        data.passwordEnable = Boolean(data.passwordEnable)
        data.ownerPasswordEnable = Boolean(data.ownerPasswordEnable)
        data.allowEarlyEntry = Boolean(data.allowEarlyEntry)
        data.muteJoinMeeting = Boolean(data.muteJoinMeeting)
        data.playTips = Boolean(data.playTips)
        data.allowSelfUnmute = Boolean(data.allowSelfUnmute)

        data.ownerPassword ? data['ownerPasswordEnable'] = true : data['ownerPasswordEnable'] = false
        this.detail = data

        if (this.detail.beginTime && this.detail.endTime) {
          const  diffValue = moment(this.detail.endTime).diff(moment(this.detail.beginTime))
          const hours =  moment.duration(diffValue).get('hours')
          const minutes = moment.duration(diffValue).get('minutes')
          this.durationTime = `${hours}:${minutes}`
        }
        this.startTime = new Date(this.detail.beginTime.replace(/-/g, "/"))
        this.showDurationLabel()
      })
    },

    // 提交预约会议表单
    handleSubmitMeetInfo:throttle(function(){
      let meetingInfo = this.detail;

      if(!meetingInfo['title']) return this.notify('会议主题不能为空');
      
      //开始时间必须晚于当前时间 比较分钟
      const startTimeStr = moment(this.startTime).format(DATETIME_MINUTE_FORMAT)
      const nowStr = moment().format(DATETIME_MINUTE_FORMAT)
      if (moment(startTimeStr).isBefore(nowStr)) {
        this.notify('开始时间不能早于当前时间');
        return
      }

      if(!this.durationLabel) return this.notify('请选择会议持续时间');

      if(meetingInfo['passwordEnable'] && !meetingInfo['password'] || meetingInfo['ownerPasswordEnable'] && !meetingInfo['ownerPassword']){
        this.notify('密码不能为空');
        return
      }

      let regMeetPassword = checkSmallPwd(meetingInfo['password'])
      let regHostPassword = checkSmallPwd(meetingInfo['ownerPassword'])
      if(meetingInfo['passwordEnable'] && !regMeetPassword ||  meetingInfo['ownerPasswordEnable'] && !regHostPassword){
        this.notify('请检测密码长度是否为4-6位正整数');
        return
      }

      // 更新结束时间
      this.onConfirmDuration()

      let paramsData = {};
      Object.keys(this.detail).forEach(key=>{
        paramsData[key] = this.detail[key];
        if(this.detail[key] === true) paramsData[key] = 1;
        else if(this.detail[key] === false) paramsData[key] = 0;
      })

      paramsData['beginTime'] = moment(this.startTime).format(DATETIME_FORMAT);
      // 编辑/创建会议
      this.meetingId ? this.editMeeting(paramsData) : this.createMeeting(paramsData) 
    },THROTLE_TIME,{ leading: true, trailing: false}),

    // 编辑会议
    async editMeeting(data){
      //会议正在进行中，无法编辑提示
      const detail = await sdk.meetingManagement.getMeetingInfo(this.meetingId)
      if (detail.status === 10) {
        this.notify('会议已经进行中，无法编辑');
        return
      }  

      sdk.meetingManagement.modifyMeeting(data)
      .then(res=>{
        this.$toast.success('预约信息更新成功');
        setTimeout(() => {
           this.gotoDetail(res)
        }, 1100);
      })
      .catch(error=>{
        console.error(error)
        this.$eventBus.$emit('request-error', error)
      })
    },

    // 创建会议
    createMeeting(data){
      sdk.meetingManagement.createMeeting(data)
      .then(res=> {
        this.$toast.success('预约成功');
        setTimeout(() => {
          this.gotoDetail(res)
        }, 1100);
      })
      .catch(error=>{
        console.error(error)
        this.$eventBus.$emit('request-error', error)
      })
    },

    gotoDetail(item) {
      const id = item.id
      const meetingType = 0
      const rtcRoomNo = item.rtcRoomNo
      const conferenceNo = item.conferenceNo

      this.$router.push({ name: 'detail', query: { meetingType, id, rtcRoomNo, conferenceNo }})
    }
  }
}
</script>

<style scoped lang="less">
.flex_center(@justify:center) { 
    display: flex;
    align-items: center;
    justify-content: @justify;
}
.multi-row {
  height: auto !important;
  overflow: auto !important;
}
.container {
   background: #FAFAFA;
   width: 100%;
   height: 100vh;
   padding-bottom: 220px;

   .info-loading {
     width: 280px;
     height: 88px;
     line-height: 88px;
     background: #FFFFFF;
     box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
     border-radius: 44px;
     border: 1px solid rgba(0, 0, 0, 0.1);
     text-align: center;
     position: absolute;
     top: 144px;
     left: calc(50% - 140px);
   }

   .group {
     margin-top: 16px;
   }

   .group .van-cell {
     font-size: 32px;
     min-height: 104px;
     line-height: 100%;
   }
   .group .van-cell__value {
     color:#333;
     font-size: 28px;
   }

   .switch {
      width: 96px;
      height: 48px;
    }

   .button {
     width: 622px;
     height: 88px;
     border-radius: 10px;
     font-size: 32px;
     font-weight: 500;
     border:none;
     display: block;
     margin:0 auto;
     margin-bottom: 32px;
   }
   .button.save {
     background: #1AB370;
     color:#fff;
     margin-top: 80px;
   }
}


::v-deep .van-nav-bar .van-icon {
  color:#333;
}
::v-deep .auto-cell .van-cell__title {
  flex: none;
}

/***** 组件兼容性 处理 px尚未自动转化****/
::v-deep .van-nav-bar__arrow,
::v-deep .van-cell__right-icon {
  font-size: 30px;
}
::v-deep .van-nav-bar__content {
  height: 88px;
  line-height: 88px;
}
::v-deep .van-nav-bar__title {
  font-size: 32px;
  font-weight: 400;
  color: #333333;
}
::v-deep .switch .van-switch__node {
  width: 44px;
  height: 44px;
  top: 2px;
}
::v-deep .van-switch--on .van-switch__node {
    -webkit-transform: translateX(48px);
    transform: translateX(48px);
}
::v-deep .multi-row .van-field__control {
   line-height: 40px;
}
</style>